<template>
  <b-card class="card-bg-apex">
    <slot />
  </b-card>
</template>

<script>
export default {
  name: 'CardChart'
}
</script>

<style scoped>
.card-bg-apex {
  background: #FFF !important;
}
.card-body {
  padding: 10px 5px 0 5px !important;
}
</style>