var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { sm: "12", lg: "6" } },
    [
      _c(
        "card-chart",
        [
          _c("apexchart", {
            ref: "realtimeChart",
            attrs: {
              type: "line",
              height: "200",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }