var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "view-signup-drivers" },
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("h2", [_vm._v(_vm._s(this.$route.meta.title))]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { disabled: _vm.loader },
                  on: { click: _vm.getMetrics },
                },
                [_c("i", { staticClass: "fa fa-refresh" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loader
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c("apex-line", {
                attrs: { "data-provider": _vm.metrics.website_users },
              }),
              _c("apex-bar", {
                attrs: {
                  "data-provider": _vm.metrics.signups,
                  title: "New Leads (Brazil)",
                },
              }),
              _c("apex-bar", {
                attrs: {
                  "data-provider": _vm.metrics.fleet,
                  title: "Mobilized Fleet",
                },
              }),
              _c("apex-bar", {
                attrs: {
                  "data-provider": _vm.metrics.bookings,
                  title: "Active Bookings",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }