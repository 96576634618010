<template>
  <b-col sm="12" lg="6">
    <card-chart>
      <apexchart 
        ref="realtimeChart" 
        type="line"
        height="200"
        :options="chartOptions" 
        :series="series"
      />
    </card-chart>
  </b-col>
</template>

<script>
import CardChart from '@components/shared/card-chart'
export default {
  name: 'ApexLine',
  components: {
    CardChart
  },
  props: {
    dataProvider: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      series: this.dataProvider.data,
      chartOptions: {
        colors: ['#ff355a', '#17ead9', '#f02fc2'],
        chart: {
          height: 350,
          type: 'line',
          dropShadow: false,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        grid: {
          show: true,
          strokeDashArray: 0,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        markers: {
          size: 4,
          colors: ['#ff355a', '#17ead9', '#f02fc2'],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dropShadow: {
          enabled: true,
          opacity: 0.3,
          blur: 5,
          left: -7,
          top: 22
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: `${this.title} - ${this.dataProvider.counter}`,
          align: 'left',
          style: {
            color: '#000'
          }
        },
        xaxis: {
          categories: this.formatDate(this.dataProvider.categories),
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              colors: '#000',
              fontSize: '10px',
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: '#000'
            }
          }
        }
      }
    }
  },
  methods : {
    formatDate (dates) {
      return dates.map(date => {
        let checkDate = this.$moment(date, 'YYYY-MM-DD');
        if (!checkDate.isValid()) checkDate = this.$moment(date, 'YYYYWW');
        return checkDate.format('YYYY-ww');
      })
    }
  },
}
</script>