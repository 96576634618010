<template>
  <b-col sm="12" lg="6">
    <card-chart>
      <apexchart 
        type="bar" 
        height="200" 
        :options="chartOptions"
        :series="series"
      />
    </card-chart>
  </b-col>
</template>

<script>
import CardChart from '@components/shared/card-chart'
export default {
  name: 'ApexColumn',
  components: {
    CardChart
  },
  props: {
    dataProvider: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      series: this.dataProvider.data,
      chartOptions: {
        colors: ['#ff355a', '#17ead9', '#f02fc2'],
        grid: {
          show: true,
          strokeDashArray: 0,
          dropShadow: false,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
          xaxis: {
            lines: {
              show: false,
            }
          }
        },
        title: {
          text: `${this.title} - ${this.dataProvider.counter}`,
          align: 'left',
          style: {
            color: '#000'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.2,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0
        },
        legend: {
          labels: {
            colors: '#000',
            useSeriesColors: false
          },
        },
        xaxis: {
          categories: this.formatDate(this.dataProvider.categories),
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            style: {
              colors: '#000',
              fontSize: '10px',
            }
          },
        },
        yaxis: {
          title: {
            style: {
              color: '#000'
            }
          },
          labels: {
            style: {
              colors: '#000'
            }
          }
        },
      }
    }
  },
  methods : {
    formatDate (dates) {
      return dates.map(date => {
        let checkDate = this.$moment(date, 'YYYY-MM-DD');
        if (!checkDate.isValid()) checkDate = this.$moment(date, 'YYYYWW');
        return checkDate.format('YYYY-ww');
      })
    }
  }
}
</script>