<template>
  <section class="view-signup-drivers">
    <b-row class="pb-4">
      <b-col cols="10">
        <h2>{{ this.$route.meta.title }}</h2>
      </b-col>
      <b-col cols="2">
        <b-button class="float-right" :disabled="loader" @click="getMetrics">
          <i class="fa fa-refresh" />
        </b-button>
      </b-col>
    </b-row>
    <div v-if="!loader" class="row">
      <apex-line :data-provider="metrics.website_users" />
      <apex-bar :data-provider="metrics.signups" :title="'New Leads (Brazil)'" />
      <apex-bar :data-provider="metrics.fleet" :title="'Mobilized Fleet'" />
      <apex-bar :data-provider="metrics.bookings" :title="'Active Bookings'" />
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </section>
</template>

<script>
import ApexLine from '@components/graphics/apex-line';
import ApexBar from '@components/graphics/apex-bar';
import ContentLoading from '@components/content-loading';

import GET_METRICS from '@graphql/signup/queries/overview.gql';

export default {
  name: 'Dashboard',
  page: {
    title: 'Dashboard',
  },
  components: {
    ApexLine,
    ApexBar,
    ContentLoading
  },
  data () {
    return {
      metrics: {},
      loader: true
    }
  },
  mounted () {
    this.valideOneHour();
  },
  methods: {
    async getMetrics () {
      this.loader = true;
      try {
        const response = await this.$apollo.query({
          query: GET_METRICS,
          fetchPolicy: 'network-only'
        });
        this.metrics = response.data;
        this.saveInStorage();
        this.loader = false;
      } catch (err) {
        console.error(err);
      }
    },
    saveInStorage () {
      const dateTime = this.$moment().utc();
      const dataStorage = {
        ...this.metrics,
        dateTime
      }
      localStorage.setItem('dashboard', JSON.stringify(dataStorage));
    },
    loadInStorage () {
      const dashboard = JSON.parse(localStorage.getItem('dashboard'));
      if (dashboard) {
        this.metrics = dashboard;
      }
    },
    valideOneHour () {
      const dashboard = JSON.parse(localStorage.getItem('dashboard'));
      if (dashboard) {
        const dateTime = this.$moment(dashboard.dateTime);

        const differenceMinutes = this.$moment().utc().diff(dateTime, 'minutes');
        
        if (differenceMinutes > 59) {
          this.getMetrics()
        } else {
          this.loadInStorage()
          this.loader = false;
        }
      } else {
        this.getMetrics()
      }
    }
  }
};
</script>
